import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class JwtService {

  private readonly localStorageKey = 'accessToken';

  setToken(token: string) {
    localStorage.setItem(this.localStorageKey, token);
  }

  getToken(): string | null {
    return localStorage.getItem(this.localStorageKey);
  }

  isTokenValid(): boolean {
    const token = this.getParsedToken();

    if (!this.hasToken()) {
      return false;
    }

    if (this.isTokenExpired(token!.exp)) {
      return true;
    }

    this.removeToken();
    return false;
  }

  removeToken(): void {
    localStorage.removeItem(this.localStorageKey);
  }

  hasToken(): boolean {
    const token = this.getParsedToken();
    return !!token;
  }

  isTokenExpired(tokenExpUnixTimestamp: number): boolean {
    const currentTime = new Date().getTime() / 1000;
    return tokenExpUnixTimestamp > currentTime;
  }

  private getParsedToken(): { sub: string; exp: number; iat: number } | null {
    const response = this.getToken();

    if (!response) {
      return null;
    }

    return JSON.parse(atob(response.split('.')[1]));
  }
}
