import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-signature-pad',
  standalone: true,
  imports: [],
  templateUrl: './signature-pad.component.html',
  styleUrl: './signature-pad.component.scss'
})
export class SignaturePadComponent implements AfterViewInit {

  @ViewChild('signatureCanvas') signatureCanvas!: ElementRef;

  @Output() signingFinished = new EventEmitter<string>();

  private ctx!: CanvasRenderingContext2D;
  private drawing = false;

  ngAfterViewInit(): void {
    const canvas = this.signatureCanvas.nativeElement;
    this.ctx = canvas.getContext('2d');
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  }

  startDrawing(event: MouseEvent | TouchEvent): void {
    this.drawing = true;
    this.ctx.beginPath();
    this.ctx.moveTo(this.getX(event), this.getY(event));
    event.preventDefault();
  }

  draw(event: MouseEvent | TouchEvent): void {
    if (!this.drawing) return;
    this.ctx.lineTo(this.getX(event), this.getY(event));
    this.ctx.stroke();
    event.preventDefault();
  }

  stopDrawing(): void {
    this.drawing = false;
    this.signingFinished.emit(this.getSignatureAsBase64());
  }

  getSignatureAsBase64(): string {
    return this.signatureCanvas.nativeElement.toDataURL('image/png');
  }

  clearCanvas(): void {
    this.ctx.clearRect(0, 0, this.signatureCanvas.nativeElement.width, this.signatureCanvas.nativeElement.height);
  }

  //Needed because of rule @angular-eslint/template/click-events-have-key-events
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent scrolling for the spacebar
      this.clearCanvas();
    }
  }

  private getX(event: MouseEvent | TouchEvent): number {
    if (event instanceof MouseEvent) {
      return event.offsetX;
    } else {
      const target = event.target as Element;
      return event.touches[0].clientX - target.getBoundingClientRect().left;
    }
  }

  private getY(event: MouseEvent | TouchEvent): number {
    if (event instanceof MouseEvent) {
      return event.offsetY;
    } else {
      const target = event.target as Element;
      return event.touches[0].clientY - target.getBoundingClientRect().top;
    }
  }
}
