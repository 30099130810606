import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiService {

  protected get endpoint(): string {
    return `${environment.basePath}`;
  }

  protected get endpointBrokernet(): string {
    return `${environment.basePathBrokernet}`;
  }

  constructor(
    protected http: HttpClient
  ) {
  }

}
