<div class="pt-4 pb-5 text-center">
  <h2 class="servo-text-primary">
    {{ title }}
  </h2>

  @if (subTitle) {
    <p class="servo-text-secondary mb-0">
      {{ subTitle }}
    </p>
  }
</div>
