<div>
  <app-page-title
    [title]="'Signieren Sie Ihren Antrag'"></app-page-title>

  @if (!submitted) {
    <app-signature (submitted)="submitted = true"></app-signature>
  }

  @if (submitted) {
    <div
      class="alert alert-success text-center"
      role="alert">
      Ihr Antrag wurde erfolgreich signiert! <br />
      Sie können diese Seite nun schließen.
    </div>
  }
</div>
