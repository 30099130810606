<main>
  <section class="app-wrapper container">
    <div class="img-wrapper">
      <img
        ngSrc="assets/servo-logo.png"
        alt="servo-logo"
        height="45"
        width="100">
    </div>

    <router-outlet></router-outlet>
  </section>
</main>
