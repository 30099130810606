import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { JwtService } from '@base/services/jwt.service';

export const authenticationInterceptor: HttpInterceptorFn = (request, next) => {
  const jwtService = inject(JwtService);

  const jwt = jwtService.getToken();

  if (jwt !== null) {
    request = request.clone({ setHeaders: { Authorization: `Bearer ${jwt}` } });
  }

  return next(request);
};
