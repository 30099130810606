import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/api.service';
import { Observable } from 'rxjs';
import { SignatureDataModel } from '../models/signature-data.model';

@Injectable()
export class SignatureApiService extends ApiService {

  protected get serviceEndpoint(): string {
    return `${this.endpointBrokernet}/api/servo/signature`;
  }

  getSignatureInformation(insuranceId: string): Observable<SignatureDataModel> {
    return this.http.get<SignatureDataModel>(`${this.serviceEndpoint}/${insuranceId}`);
  }

  save(signature: string, insuranceId: string): Observable<void> {
    return this.http.post<void>(`${this.serviceEndpoint}/${insuranceId}`, {customerSignatureBase64Encoded: signature});
  }

  downloadOffert(insuranceId: string): Observable<Blob> {
    return this.http.get(`${this.serviceEndpoint}/download/${insuranceId}`, {responseType: 'blob'});
  }
}
