<div class="signature-pad">
  <div
    (click)="clearCanvas()"
    (keydown)="onKeyDown($event)"
    tabindex="0"
    class="clear-button">
    <i class="bi bi-x-circle"></i>
  </div>

  <canvas
    #signatureCanvas
    (mousedown)="startDrawing($event)"
    (mousemove)="draw($event)"
    (mouseup)="stopDrawing()"
    (mouseleave)="stopDrawing()"
    (touchstart)="startDrawing($event)"
    (touchmove)="draw($event)"
    (touchend)="stopDrawing()"></canvas>
</div>
