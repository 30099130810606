export enum InsuranceTypeEnum {
  ELEKTRONIK = 'ELEKTRONIK',
  BIKE = 'BIKE',
  KAUFPREIS = 'KAUFPREIS',
  GARANTIE = 'GARANTIE'
}

export const InsuranceType = {
  getTypeTranslation(insuranceType: InsuranceTypeEnum) {
    switch (insuranceType) {
      case InsuranceTypeEnum.BIKE:
        return 'Fahrrad/E-Bike';
      case InsuranceTypeEnum.ELEKTRONIK:
        return 'Elektronik';
      case InsuranceTypeEnum.KAUFPREIS:
        return 'Kaufpreiskasko';
      case InsuranceTypeEnum.GARANTIE:
        return 'Garantieverlängerung';
      default:
        return '';
    }
  }
};

