import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { SignatureApiService } from '../../services/signature-api.service';
import { AsyncPipe, DatePipe } from '@angular/common';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import { catchError, Observable, of } from 'rxjs';
import { SignatureDataModel } from '../../models/signature-data.model';
import { InsuranceType, InsuranceTypeEnum } from '@base/models/insurance-type.enum';

@Component({
  selector: 'app-signature',
  standalone: true,
  imports: [
    AsyncPipe,
    SignaturePadComponent,
    DatePipe
  ],
  templateUrl: './signature.component.html',
  styleUrl: './signature.component.scss',
  providers: [SignatureApiService]
})
export class SignatureComponent implements OnInit {

  currentDate = new Date();

  signatureApiService = inject(SignatureApiService);
  signatureData$: Observable<SignatureDataModel> | null = null;

  signatureBase64: string | null = null;

  isLoading = false;
  showError = false;

  @Output() submitted = new EventEmitter<void>();

  ngOnInit() {
    const url = new URL(window.location.href);

    if (!url.searchParams.has('id')) {
      this.signatureData$ = null;
    }

    this.signatureData$ = this.signatureApiService
      .getSignatureInformation(url.searchParams.get('id')!)
      .pipe(catchError(err => {
        this.showError = true;

        if (err) {
          console.error(err);
        }

        return of();
      }));
  }

  getInsuranceTranslation(type: InsuranceTypeEnum): string {
    return InsuranceType.getTypeTranslation(type);
  }

  downloadDocument(insuranceId: string): void {
    this.signatureApiService
      .downloadOffert(insuranceId)
      .subscribe(blob => {
        if (isIOS) {
          // For iOS devices, open the PDF in a new tab
          window.open(URL.createObjectURL(blob), '_blank');
          return;
        }

        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = `Versicherungsantrag.pdf`;

        document.body.appendChild(a); // Append to the document
        a.click(); // Trigger download
        a.remove(); // Clean up
      });
  }

  handleKeydown(event: KeyboardEvent, insuranceId: string): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent default behavior (e.g., scrolling for Space)
      this.downloadDocument(insuranceId);
    }
  }

  submit(insuranceId: string): void {
    this.isLoading = true;

    if (this.signatureBase64 === null) {
      return;
    }
    this.signatureApiService
      .save(this.signatureBase64, insuranceId)
      .subscribe({
        next: () => this.submitted.emit(),
        complete: () => this.isLoading = false
      });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

