import { Component } from '@angular/core';

import { PageTitleComponent } from '@base/components/page-title/page-title.component';
import { SignatureComponent } from '../../components/signature/signature.component';

@Component({
  selector: 'app-signature-page',
  standalone: true,
  imports: [
    PageTitleComponent,
    SignatureComponent
  ],
  templateUrl: './signature-page.component.html',
  styleUrl: './signature-page.component.scss'
})
export class SignaturePageComponent {

  submitted = false;

}
